import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import PageBlock from "../../components/Layout/PageBlock";
import PageLayout from "../../components/Layout/PageLayout";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

import { ArrowRight, ExternalLink, FileText } from "react-feather";
import { ApplyLink, staticImgContainer, CTALink, primaryColour, PageBlockNextPageDivider, InlineExternalLink } from "../../styles/component-stylings";
import { APPLY_LINK, DSC_EMAIL } from "../../helpers/links";

const TSF_PAYSCALE = {
    TSF1: "$118,516 - $131,849",
    TSF2: "$136,916 - $153,043",
    TSF3: "$160,727 - $177,492",
    TSF4: "$182,374 - $199,150",
};

const CTAButton = styled(ApplyLink)`
    margin: 0;
    font-size: 1rem;
`;

const TableContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const TableHead = styled.th`
    text-align: left;
    padding-right: 1rem;
`;

const PAGE_TITLE = "Technical Specialist Framework";

const TechnicalSpecialistFrameworkPage = ({ pageContext }) => {
    return (
        <PageLayout pageTitle={PAGE_TITLE} pageContext={pageContext}>
            <Helmet>
                <meta
                    name='description'
                    content='Overview page for the Technical Specialist Framework for Digital Surveillance Collection from the Australian Federal Police'
                />
            </Helmet>
            <PageBlock pageContext={pageContext} backgroundColour='white' isFirstBlock>
                <Breadcrumb pageContext={pageContext} />
                <h1>{PAGE_TITLE}</h1>
                <StaticImage
                    style={staticImgContainer}
                    imgStyle={{ objectPosition: "center bottom" }}
                    src='../../images/expert.jpg'
                    alt='Technical Specialist'
                    placeholder='blurred'
                    loading='lazy'
                />
                <p>
                    We recognise that technical specialists in their field should be recognised and compensated accordingly. We are the one of the few
                    government organisations that provide a band structure specific to technical specialists, outside the standard APS levels. TSF roles
                    recognise high level specialty or technical skill.
                </p>
                <p>Think competitive pay with public service benefits without the management responsibility.</p>
                <p>There are 4 levels to the TSF, demonstrated by different levels of technical specialisation:</p>
                <TableContainer>
                    <table>
                        <tr>
                            <TableHead>Level</TableHead>
                            <TableHead>Pay Scale</TableHead>
                        </tr>
                        {Object.keys(TSF_PAYSCALE).map((tsf) => (
                            <tr>
                                <td>{tsf}</td>
                                <td>{TSF_PAYSCALE[tsf]}</td>
                            </tr>
                        ))}
                    </table>
                </TableContainer>
                <p>For more information: </p>
                <ul>
                    <li>
                        <InlineExternalLink href='https://www.afp.gov.au/about-us/afp-enterprise-agreement-2017-2020#III' target='_blank' rel='external'>
                            TSF in the AFP Enterprise Agreement 2017-2020
                        </InlineExternalLink>
                    </li>
                    <li>
                        <InlineExternalLink
                            href='https://www.afp.gov.au/sites/default/files/PDF/IPS/BPG-TechnicalSpecialistFramework.pdf'
                            target='_blank'
                            rel='external'
                        >
                            TSF Better Practice Guide (PDF)
                        </InlineExternalLink>
                    </li>
                    <li>
                        <InlineExternalLink
                            href='https://www.afp.gov.au/sites/default/files/PDF/07072021-Determination2of2020.pdf#page=6'
                            target='_blank'
                            rel='external'
                        >
                            AFP Base Salary Rates [TSF - Page 6] (PDF)
                        </InlineExternalLink>
                    </li>
                </ul>
                <CTAButton href={APPLY_LINK} target='_blank' rel='external'>
                    <span>Apply now</span>
                    <ExternalLink />
                </CTAButton>
                <p>
                    There will be times were there are no posted vacancies for DSC, but don't let this deter you. Consider sending us your CV directly. Please
                    reach out to our team for a confidential discussion - we are always looking for outstanding candidates.
                </p>
                <p>
                    <strong>Candidates must be Australian citizens and able to obtain an Australian Government security clearance.</strong>
                </p>
                <CTAButton href={`mailto:${DSC_EMAIL}?subject=[DSC Recruitment TSF]`} target='_blank' rel='noopener noreferrer'>
                    <span>Send us your CV</span>
                    <FileText />
                </CTAButton>
                <PageBlockNextPageDivider>
                    <CTALink $invert $borderColour={primaryColour} to='/contact-us' rel='next'>
                        <span>Contact us</span>
                        <ArrowRight />
                    </CTALink>
                </PageBlockNextPageDivider>
            </PageBlock>
        </PageLayout>
    );
};

export default TechnicalSpecialistFrameworkPage;
